
import { defineComponent } from "vue";
import Billing from "../components/Billing.vue";
import { mapActions } from "pinia";

export default defineComponent({
  components: {
    Billing,
  },
});
