import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid formgrid mt-2 mb-2" }
const _hoisted_2 = {
  class: "grid sm:col-12 md:col-12 lg:col-12 xl:col-8",
  style: {"align-content":"flex-start"}
}
const _hoisted_3 = { class: "col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6 mt-1" }
const _hoisted_4 = { class: "p-inputgroup" }
const _hoisted_5 = { class: "col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6 mt-1" }
const _hoisted_6 = { class: "p-inputgroup" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "p-error" }
const _hoisted_9 = { class: "col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6 mt-1" }
const _hoisted_10 = { class: "p-inputgroup" }
const _hoisted_11 = { style: {"max-width":"350px"} }
const _hoisted_12 = { style: {"font-size":"9px"} }
const _hoisted_13 = { class: "col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6 mt-1" }
const _hoisted_14 = { class: "p-inputgroup" }
const _hoisted_15 = { class: "col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6 mt-1" }
const _hoisted_16 = { class: "p-inputgroup" }
const _hoisted_17 = { class: "col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6 mt-1" }
const _hoisted_18 = { class: "p-inputgroup" }
const _hoisted_19 = { class: "col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6 mt-1" }
const _hoisted_20 = { class: "p-inputgroup" }
const _hoisted_21 = { class: "col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6 mt-1" }
const _hoisted_22 = { class: "p-inputgroup" }
const _hoisted_23 = { class: "grid col-12 sm:col-12 md:col-12 lg:col-12 xl:col-4 mt-1 ml-0 pl-0" }
const _hoisted_24 = { class: "w-full px-2" }
const _hoisted_25 = { class: "col-12 mt-2" }
const _hoisted_26 = { class: "p-inputgroup" }
const _hoisted_27 = { class: "grid col-12 p-0 m-0 mt-2" }
const _hoisted_28 = { class: "col-12" }
const _hoisted_29 = { class: "grid formgrid" }
const _hoisted_30 = { class: "col-12 mt-2" }
const _hoisted_31 = { class: "grid formgrid mt-2" }
const _hoisted_32 = { class: "col-12 mt-2 lg:col-6" }
const _hoisted_33 = { class: "p-inputgroup" }
const _hoisted_34 = { key: 0 }
const _hoisted_35 = { class: "p-error" }
const _hoisted_36 = { class: "col-12 mt-2 lg:col-6" }
const _hoisted_37 = { class: "p-inputgroup" }
const _hoisted_38 = { key: 0 }
const _hoisted_39 = { class: "p-error" }
const _hoisted_40 = { key: 1 }
const _hoisted_41 = { class: "p-error" }
const _hoisted_42 = { class: "col-12 mt-2" }
const _hoisted_43 = { class: "col-12 mt-2" }
const _hoisted_44 = { class: "flex justify-content-end mt-2" }
const _hoisted_45 = { class: "grid formgrid" }
const _hoisted_46 = { class: "col-12" }
const _hoisted_47 = { class: "grid formgrid" }
const _hoisted_48 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_ContactItems = _resolveComponent("ContactItems")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_TextArea = _resolveComponent("TextArea")!
  const _component_CollapsibleSection = _resolveComponent("CollapsibleSection")!
  const _component_AttachmentRecords = _resolveComponent("AttachmentRecords")!
  const _component_Button = _resolveComponent("Button")!
  const _component_SplitButton = _resolveComponent("SplitButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_CollapsibleSection, {
      isOpen: _ctx.sectionsStatus.ticketEntry,
      header: "Ticket Entry",
      "onUpdate:isOpen": _cache[13] || (_cache[13] = ($event: any) => (_ctx.toggleSectionIcon('ticketEntry')))
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _cache[23] || (_cache[23] = _createElementVNode("span", {
                  class: "p-inputgroup-addon",
                  style: { width: '101px' }
                }, "Customer", -1)),
                _createVNode(_component_Search, {
                  modelValue: _ctx.newTicketCust,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newTicketCust) = $event)),
                  "data-test-id": "CustomerSearch",
                  disabled: _ctx.editTicketField,
                  label: "name",
                  file: "Bill",
                  placeHolder: "Enter Customer ID or Name",
                  onFilterCust: _cache[1] || (_cache[1] = 
                    (event) => {
                      this.newTicket.cust = event.value.cust_id;
                      _ctx.setValidParts(event.value.type);
                    }
                  )
                }, null, 8, ["modelValue", "disabled"])
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _cache[24] || (_cache[24] = _createElementVNode("span", {
                  class: "p-inputgroup-addon",
                  style: { width: '101px' }
                }, "Type", -1)),
                _createVNode(_component_Dropdown, {
                  options: _ctx.getBillingTypes,
                  optionLabel: "billing_type",
                  optionValue: "billing_type",
                  modelValue: _ctx.v$.newTicket.type.$model,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.v$.newTicket.type.$model) = $event)),
                  placeholder: "Select Type",
                  disabled: _ctx.editTicketField,
                  onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setBillable(_ctx.v$.newTicket.type.$model))),
                  class: _normalizeClass({
                    'p-invalid':
                      _ctx.submitted && _ctx.v$.newTicket.type.required.$invalid,
                  })
                }, null, 8, ["options", "modelValue", "disabled", "class"])
              ]),
              (_ctx.submitted && _ctx.v$.newTicket.type.required.$invalid)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("small", _hoisted_8, _toDisplayString(_ctx.v$.newTicket.type.required.$message), 1)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _cache[25] || (_cache[25] = _createElementVNode("span", {
                  class: "p-inputgroup-addon",
                  style: { width: '101px' }
                }, "Part", -1)),
                _createVNode(_component_Dropdown, {
                  placeholder: "Select Part",
                  options: _ctx.parts?.sort(_ctx.sortArray),
                  optionLabel: "wrap_desc",
                  optionValue: "part_no",
                  modelValue: _ctx.part,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.part) = $event)),
                  disabled: _ctx.editTicketField,
                  onChange: _ctx.setPart,
                  showClear: true
                }, {
                  option: _withCtx((slotProps) => [
                    _createElementVNode("div", _hoisted_11, [
                      _createTextVNode(_toDisplayString(slotProps.option.part_no) + " ", 1),
                      _createElementVNode("i", _hoisted_12, _toDisplayString(slotProps.option.wrap_desc), 1)
                    ])
                  ]),
                  _: 1
                }, 8, ["options", "modelValue", "disabled", "onChange"])
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _cache[26] || (_cache[26] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "Assigned To", -1)),
                _createVNode(_component_Dropdown, {
                  type: "text",
                  modelValue: this.newTicket.assigned_to,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((this.newTicket.assigned_to) = $event)),
                  options: _ctx.getUsers,
                  optionLabel: "user_name",
                  optionValue: "user_id",
                  placeholder: "Select Assignee",
                  disabled: _ctx.editTicketField
                }, null, 8, ["modelValue", "options", "disabled"])
              ])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _cache[27] || (_cache[27] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "Status", -1)),
                _createVNode(_component_Dropdown, {
                  type: "text",
                  modelValue: this.newTicket.status,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((this.newTicket.status) = $event)),
                  options: _ctx.getTicketStatus,
                  optionLabel: "status_desc",
                  optionValue: "status_code",
                  placeholder: "Select Status",
                  disabled: _ctx.editTicketField
                }, null, 8, ["modelValue", "options", "disabled"])
              ])
            ]),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                _cache[28] || (_cache[28] = _createElementVNode("span", { class: "p-inputgroup-addon" }, "Priority", -1)),
                _createVNode(_component_Dropdown, {
                  type: "text",
                  modelValue: this.newTicket.priority,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((this.newTicket.priority) = $event)),
                  options: _ctx.getTicketPriority,
                  optionLabel: "priority_desc",
                  optionValue: "priority_code",
                  placeholder: "Select Priority",
                  disabled: _ctx.editTicketField
                }, null, 8, ["modelValue", "options", "disabled"])
              ])
            ]),
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                _cache[29] || (_cache[29] = _createElementVNode("span", {
                  class: "p-inputgroup-addon",
                  style: { width: '101px' }
                }, "Date", -1)),
                _createVNode(_component_Calendar, {
                  showIcon: true,
                  modelValue: this.newTicket.date,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((this.newTicket.date) = $event)),
                  dateFormat: "mm-dd-yy",
                  manualInput: false,
                  disabled: _ctx.editTicketField
                }, null, 8, ["modelValue", "disabled"])
              ])
            ]),
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("div", _hoisted_22, [
                _createVNode(_component_InputSwitch, {
                  modelValue: _ctx.billable,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.billable) = $event)),
                  id: "binary",
                  class: "field-checkbox mr-1",
                  binary: true,
                  disabled: true,
                  trueValue: "Y",
                  falseValue: null
                }, null, 8, ["modelValue"]),
                _cache[30] || (_cache[30] = _createElementVNode("label", { for: "billable" }, "Billable", -1)),
                _createVNode(_component_InputSwitch, {
                  modelValue: this.newTicket.closed,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((this.newTicket.closed) = $event)),
                  id: "binary",
                  trueValue: "Y",
                  falseValue: "",
                  binary: true,
                  class: "field-checkbox mr-1 ml-2",
                  disabled: _ctx.editTicketField
                }, null, 8, ["modelValue", "disabled"]),
                _cache[31] || (_cache[31] = _createElementVNode("label", { for: "closed" }, "Closed", -1))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("div", _hoisted_24, [
              _createVNode(_component_ContactItems, {
                contact: _ctx.newTicket.contact_items,
                contactId: "contact",
                onUpdateContact: _ctx.updateContact,
                inline: true,
                cust_id: _ctx.newTicket.cust
              }, null, 8, ["contact", "onUpdateContact", "cust_id"])
            ])
          ]),
          _createElementVNode("div", _hoisted_25, [
            _createElementVNode("div", _hoisted_26, [
              _cache[32] || (_cache[32] = _createElementVNode("span", {
                class: "p-inputgroup-addon",
                style: { width: '101px' }
              }, "Title", -1)),
              _createVNode(_component_InputText, {
                type: "text",
                modelValue: this.newTicket.title,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((this.newTicket.title) = $event)),
                disabled: _ctx.editTicketField
              }, null, 8, ["modelValue", "disabled"])
            ])
          ]),
          _createElementVNode("div", _hoisted_27, [
            _createElementVNode("div", _hoisted_28, [
              _createVNode(_component_TextArea, {
                class: "resizable-textarea",
                type: "text",
                rows: "5",
                placeholder: "Enter Description",
                modelValue: this.newTicket.desc,
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((this.newTicket.desc) = $event)),
                disabled: _ctx.editTicketField
              }, null, 8, ["modelValue", "disabled"])
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["isOpen"]),
    (_ctx.isEditingTicket)
      ? (_openBlock(), _createBlock(_component_CollapsibleSection, {
          key: 0,
          isOpen: _ctx.sectionsStatus.attachments,
          header: "Attachments",
          "onUpdate:isOpen": _cache[16] || (_cache[16] = ($event: any) => (_ctx.toggleSectionIcon('attachments')))
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_29, [
              _createElementVNode("div", _hoisted_30, [
                _createVNode(_component_AttachmentRecords, {
                  hostFile: "TICKET",
                  record: _ctx.ticket,
                  onOnDeleted: _cache[14] || (_cache[14] = 
                (event) => {
                  _ctx.handleDeleted(event);
                }
              ),
                  onOnSaved: _cache[15] || (_cache[15] = 
                (event) => {
                  _ctx.handleSaved(event);
                }
              )
                }, null, 8, ["record"])
              ])
            ])
          ]),
          _: 1
        }, 8, ["isOpen"]))
      : _createCommentVNode("", true),
    _createVNode(_component_CollapsibleSection, {
      isOpen: _ctx.sectionsStatus.addTime,
      header: "Add Time",
      "onUpdate:isOpen": _cache[21] || (_cache[21] = ($event: any) => (_ctx.toggleSectionIcon('addTime')))
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_31, [
          _createElementVNode("div", _hoisted_32, [
            _createElementVNode("div", _hoisted_33, [
              _cache[33] || (_cache[33] = _createElementVNode("span", {
                class: "p-inputgroup-addon",
                style: { width: '101px' }
              }, "Date", -1)),
              _createVNode(_component_Calendar, {
                showIcon: true,
                modelValue: _ctx.v$.newBill.date.$model,
                "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.v$.newBill.date.$model) = $event)),
                dateFormat: "mm-dd-yy",
                manualInput: false,
                showButtonBar: true
              }, null, 8, ["modelValue"])
            ]),
            (_ctx.submitted && _ctx.v$.newBill.date.requiredIf.$invalid)
              ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                  _createElementVNode("small", _hoisted_35, _toDisplayString(_ctx.v$.newBill.date.requiredIf.$message), 1)
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_36, [
            _createElementVNode("div", _hoisted_37, [
              _cache[34] || (_cache[34] = _createElementVNode("span", {
                class: "p-inputgroup-addon",
                style: { width: '101px' }
              }, "Hours", -1)),
              _createVNode(_component_InputText, {
                style: { width: '1%' },
                modelValue: _ctx.v$.newBill.hours.$model,
                "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.v$.newBill.hours.$model) = $event)),
                type: "text"
              }, null, 8, ["modelValue"])
            ]),
            (_ctx.submitted && _ctx.v$.newBill.hours.required.$invalid)
              ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                  _createElementVNode("small", _hoisted_39, _toDisplayString(_ctx.v$.newBill.hours.required.$message), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.submitted && _ctx.v$.newBill.hours.between.$invalid)
              ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                  _createElementVNode("small", _hoisted_41, _toDisplayString(_ctx.v$.newBill.hours.between.$message), 1)
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_42, [
            _createVNode(_component_TextArea, {
              class: "resizable-textarea",
              type: "text",
              rows: "5",
              placeholder: "Enter Description",
              modelValue: this.newBill.desc,
              "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((this.newBill.desc) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_43, [
            _createVNode(_component_TextArea, {
              class: "resizable-textarea",
              type: "text",
              rows: "5",
              placeholder: "Internal Note",
              modelValue: this.newBill.notes,
              "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((this.newBill.notes) = $event))
            }, null, 8, ["modelValue"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["isOpen"]),
    _createElementVNode("div", _hoisted_44, [
      _createElementVNode("div", _hoisted_45, [
        _createElementVNode("div", _hoisted_46, [
          (_ctx.showCancelButton)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                "data-test": "cancelQuickTicketDialog",
                label: "Cancel",
                icon: "pi pi-times",
                onClick: _cache[22] || (_cache[22] = 
              () => {
                this.isHidden = false;
                _ctx.$emit('hide', this.isHidden);
              }
            ),
                class: "p-button-text"
              }))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_47, [
        _createElementVNode("div", _hoisted_48, [
          (_ctx.saveOptions?.length > 0)
            ? (_openBlock(), _createBlock(_component_SplitButton, {
                key: 0,
                "data-test": "saveQuickTicket",
                label: "Save",
                icon: "pi pi-save",
                iconPos: "left",
                model: 
              _ctx.saveOptions.filter((element) => {
                return element.label;
              })
            ,
                loading: _ctx.isLoadingTicket,
                onClick: _ctx.saveQuickTicket
              }, null, 8, ["model", "loading", "onClick"]))
            : (_openBlock(), _createBlock(_component_Button, {
                key: 1,
                label: "Save",
                icon: "pi pi-save",
                iconPos: "left",
                loading: _ctx.isLoadingTicket,
                onClick: _ctx.saveQuickTicket
              }, null, 8, ["loading", "onClick"]))
        ])
      ])
    ])
  ]))
}