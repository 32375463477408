
import { defineComponent } from "vue";
import Button from "primevue/button";
import { cloneDeep } from "lodash";
import Contact from "../Autocompletes/Contact.vue";
import ContactDialog from "../Contacts/ContactDialog.vue";
import { mapState } from "pinia";
import { useContactControlStore } from "@/store/data/controls/contact";

export default defineComponent({
  name: "ContactItems",
  components: {
    Button,
    Contact,
    ContactDialog,
  },
  props: {
    contact: {
      type: Array,
    },
    contactId: {
      type: String,
      default: "",
    },
    inline: {
      type: Boolean,
      default: false,
    },
    cust_id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      contact_items: [{ [this.contactId]: "" }] as any[],
      showContactDialog: false,
    };
  },
  mounted() {
    this.updateContactProp();
  },
  computed: {
    ...mapState(useContactControlStore, {
      getContactLookup: "getContactLookup",
    }),
    contactHeaderClass(): string {
      return "grid col-12 p-0 m-0 ";
    },
    disabled(): boolean {
      return false;
    },
  },
  methods: {
    updateContactProp() {
      if (this.contact && this.contact?.length > 0) {
        this.contact_items = cloneDeep(this.contact);
      }
    },
    addContactLine(index: number) {
      this.contact_items.splice(index + 1, 0, { [this.contactId]: "" });
      this.$emit("updateContact", this.contact_items);
    },
    updateContact() {
      this.$emit("updateContact", this.contact_items);
    },
    selectContact(event: any, item: any) {
      item[this.contactId] = event.contact_id;
      this.$emit("updateContact", this.contact_items);
    },
    deleteContactLine(index: number) {
      this.contact_items.splice(index, 1);
      this.$emit("updateContact", this.contact_items);
      if (this.contact_items.length === 0) {
        this.contact_items.push({ [this.contactId]: "" });
      }
    },
    handleNewContact(event: any) {
      this.showContactDialog = false;
      if (
        this.contact_items.length === 1 &&
        this.contact_items[0][this.contactId] === ""
      ) {
        this.contact_items = [];
      }
      this.contact_items.push({ [this.contactId]: event.contact_id });
      this.$emit("updateContact", this.contact_items);
    },
  },
});
