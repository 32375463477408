import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-2"
}
const _hoisted_2 = { class: "w-full" }
const _hoisted_3 = { class: "p-inputgroup" }
const _hoisted_4 = {
  key: 0,
  class: "p-inputgroup-addon"
}
const _hoisted_5 = {
  class: "w-full m-0 p-0",
  style: {"max-height":"40px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Contact = _resolveComponent("Contact")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ContactDialog = _resolveComponent("ContactDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.inline)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _cache[2] || (_cache[2] = [
          _createElementVNode("strong", null, "Contact", -1)
        ])))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.contactHeaderClass)
    }, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contact_items, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "mb-1"
          }, [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.inline)
                ? (_openBlock(), _createElementBlock("small", _hoisted_4, "Contact"))
                : _createCommentVNode("", true),
              _createVNode(_component_Contact, {
                "selected-id": item[_ctx.contactId],
                onItemSelect: ($event: any) => (_ctx.selectContact($event, item)),
                disabled: _ctx.disabled,
                "contact-lookup": _ctx.getContactLookup,
                "cust-id": _ctx.cust_id
              }, null, 8, ["selected-id", "onItemSelect", "disabled", "contact-lookup", "cust-id"]),
              _createVNode(_component_Button, {
                title: "Add Contact Name",
                icon: "pi pi-plus",
                class: "text-sm font-bold",
                style: {"min-width":"1.5rem"},
                onClick: ($event: any) => (_ctx.addContactLine(index)),
                disabled: _ctx.disabled
              }, null, 8, ["onClick", "disabled"]),
              _createVNode(_component_Button, {
                icon: "pi pi-trash",
                title: "Remove Contact Name",
                class: "text-sm font-bold",
                style: {"min-width":"1.5rem"},
                onClick: ($event: any) => (_ctx.deleteContactLine(index)),
                disabled: _ctx.disabled
              }, null, 8, ["onClick", "disabled"])
            ])
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_Button, {
          icon: "pi pi-plus",
          title: "New Contact",
          label: "New Contact",
          class: "text-sm font-bold",
          style: {"width":"10rem","float":"right","height":"36px"},
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showContactDialog = true)),
          disabled: _ctx.disabled
        }, null, 8, ["disabled"])
      ])
    ], 2),
    _createVNode(_component_ContactDialog, {
      visible: _ctx.showContactDialog,
      definedCustomerId: _ctx.cust_id,
      isNewContact: true,
      onOnDialogClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showContactDialog = false)),
      onOnContactSave: _ctx.handleNewContact
    }, null, 8, ["visible", "definedCustomerId", "onOnContactSave"])
  ], 64))
}