import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    "data-test": "quickTicketDialog",
    header: "New Ticket",
    visible: this.isHidden,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((this.isHidden) = $event)),
    modal: true,
    onHide: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('hide', this.isHidden))),
    style: { width: '90%', maxWidth: '1200px' },
    class: "p-fluid",
    draggable: false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _renderSlot(_ctx.$slots, "content")
      ])
    ]),
    _: 3
  }, 8, ["visible"]))
}